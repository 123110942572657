import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import React from "react";

export const Contact = (props) => {
  const formRef = useRef();
  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
  })
  const [loading, setLoading] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true);

    emailjs.send(
      "service_kpww8x9",
      "template_dsnjmp6",
      {
        from_name: form.name,
        to_name: 'Klamin-trans',
        from_email: form.email,
        to_email: 'klamin-trans@yandex.ru',
        message: form.message
      },
      "4C_RmHGpV4pRq6TZN"
    )
      .then(() => {
        setLoading(false);
        alert('Спасибо! Мы свяжемся с Вами как можно скорее!')

        setForm({
          name: "",
          email: "",
          message: "",
        })
      }, (error) => {
        setLoading(false);
        console.log(error)
        alert('Something went wrong.')
      })
  }

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>связаться с нами</h2>
                <p>
                  Пожалуйста, заполните форму ниже, чтобы отправить нам сообщение и мы свяжемся с Вами в ближайшее время.
                </p>
              </div>
              <form ref={formRef} onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Ваше имя"
                        value={form.name}
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Ваш Email"
                        value={form.email}
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Ваше сообщение"
                    value={form.message}
                    required
                    onChange={handleChange}
                  ></textarea>
                  <div className="checkbox">
                    <input type="checkbox" className="checkbox" required></input>
                    <p>Я согласен с политикой в отношении обработки персональных данных</p>
                  </div>

                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">

                  {loading ? 'Отправлено' : 'Отправить'}
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Контактная информация</h3>
              <p>Общество с ограниченной ответственностью "Кламин-транс"</p>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Адрес
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <a href="tel:+375447501863" className="link">
                  <span>
                    <i className="fa fa-phone"></i> Телефоны
                  </span>{" "}
                  {props.data ? props.data.phone1 : "loading"}
                </a>
              </p>
              <p>
                <a href="tel:+375445219357" className="link">
                  {props.data ? props.data.phone2 : "loading"}
                </a>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <a href="mailto:klamin-trans@yandex.ru" className="link">
                  <span>
                    <i className="fa fa-envelope-o"></i> Email
                  </span>{" "}
                  {props.data ? props.data.email : "loading"}
                </a>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-clock-o"></i> Время работы
                </span>{" "}
                {props.data ? props.data.time : "loading"}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <div className="contact-item">
                  <h3>Связаться с нашими сотрудниками</h3>
                </div>
                <ul>
                  <li>
                    <a href={props.data ? props.data.telegram : "/"} target="_blank" rel="noreferrer">
                      <img src="img/icons/telegram.svg" alt="telegram"></img>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.viber : "/"} target="_blank" rel="noreferrer">
                      <img src="img/icons/viber.svg" alt="viber"></img>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.instagram : "/"} target="_blank" rel="noreferrer">
                      <img src="img/icons/instagram.svg" alt="instagram"></img>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.whatsapp : "/"} target="_blank" rel="noreferrer">
                      <img src="img/icons/whatsapp.svg" alt="whatsup"></img>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2023 LCC Klamin-trans. Политика в отношении обработки персональных данных{" "}
            <a href="SecurePolicy.pdf" rel="nofollow" target="_blank">
              ООО "Кламин-транс"
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
